@import "../../../variables";

.modal-add-post {
  z-index: 30;
  .modal-custom {
    max-width: 1200px;
    padding: 0;
    width: 100%;
    height: 100vh;
    @media screen and (min-width: $lg) {
      width: 95%;
      height: auto;
    }
    .modal-header {
      @apply pb-2 border-b border-gray-300 md:pb-0 md:border-b-0;
    }
    .modal-body {
      // @apply mr-0 md:mr-2 lg:mr-6;
      @apply px-6 mx-0 mt-2;
      max-height: calc(100vh - 150px);
      @media screen and (min-width: $sm) {
        max-height: form-details calc(100vh - 172px);
      }
      .modal-fieldset {
        @apply flex gap-x-4 justify-start border-0;
        padding: 0;

        .modal-legend {
          display: none;
        }
        .cate-menu-list {
          margin-top: 10px;
          border-radius: 3px;
          height: fit-content;
        }
        .seminar-schedule {
          @apply flex justify-between items-center mt-4;
          .post-title {
            font-weight: 400;
          }
          .add-seminar {
            @apply flex gap-2 items-center relative cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-[#bceb77] before:origin-center before:h-[2px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-[#bceb77] after:origin-center after:h-[2px] after:w-0 hover:after:w-[50%] after:right-[50%] after:bottom-0;
          }
        }
        .post-detail-title {
          @apply mt-4;
          font-weight: 400;
        }
        .form-details {
          @media screen and (min-width: $lg) {
            width: calc(100% - 300px);
            // width: 100%
          }
          .upload-image-preview {
            @apply mt-auto;
          }
          .image-setting {
            @apply flex gap-x-3 pt-1;
            border-radius: 3px;
            .text-field-custom {
              @apply mt-2 text-sm;
            }
          }
          .more-image-setting {
            @apply flex flex-wrap;
            .image-control {
              @apply p-1 flex gap-x-3 pt-1 w-full sm:w-1/2 lg:w-full xl:w-1/2;
              border-radius: 3px;
              .text-field-custom {
                @apply mt-2;
              }
            }
          }
          .input-date {
            @apply w-full flex mt-4 flex-wrap gap-y-3 md:gap-y-4;
            .input-half {
              @apply w-full md:w-1/2;
              .date-input {
                @apply mx-0 w-full;
                label {
                  top: -8px;
                  &.MuiInputLabel-shrink {
                    top: 0;
                  }
                }
                input {
                  @apply py-2;
                }
              }
              &.pr {
                @apply md:pr-1;
              }
              &.pl {
                @apply md:pl-1;
              }
            }
          }
         
          .input-time {
            @apply w-full grid mt-4 gap-3 md:gap-y-4;
            grid-template-columns: 1fr 1fr 3fr;
            .input-half {
              @apply w-full flex;
              .date-input {
                @apply mx-0 w-full border;
                label {
                  top: -8px;
                  &.MuiInputLabel-shrink {
                    top: 0;
                  }
                }
                input {
                  @apply py-2;
                }
                .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
                  padding: 8.5px;
                }
              }
              &.pr {
                @apply md:pr-1;
              }
              &.pll {
                @apply md:pr-1;
              }
              &.pl {
                @apply md:pl-1;
              }
            }
          }
          .text-field-custom {
            @apply mt-3;
          }
          .ck-content {
            @apply w-[800px] my-2 ;
            label {
              @apply text-gray-500;
              font-weight: 400;
            }
          }

          .setting-controls {
            @apply flex flex-wrap gap-y-2 mt-2 mb-4;
            .switch-form {
              @apply w-full px-1 sm:w-1/3 lg:sm:w-1/2 xl:w-1/3;
              label {
                @apply w-full border border-gray-300 mx-auto sm:gap-x-4 px-2;
                justify-content: center;
                margin-left: 0;
                margin-right: auto;
                span {
                  font-size: 12px;
                  @apply md:text-sm;
                }
              }
            }
            .input-group {
              @apply w-full xs:w-1/2 sm:w-1/3 lg:w-1/2 xl:w-1/3 sm:gap-x-4 px-1;
              justify-content: space-between;

              .inp {
                @apply w-full flex items-center border border-gray-300 px-3;
                padding-top: 7px;
                padding-bottom: 7px;
                .title {
                  font-weight: 400;
                  user-select: none;
                  font-size: 12px;
                  @apply md:text-sm w-full text-center;
                }
                button {
                  height: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
    .modal-footer {
      justify-content: flex-end;
    }
  }
}


.ck-input {
    @apply mx-auto w-[60%] rounded-lg shadow-md p-4 flex justify-center items-center flex-col bg-slate-100  ;
    .modal-header{
        @apply flex justify-between items-center w-full text-lg ;
    }
    .ck-content{
        @apply w-full h-auto;
    }
    .modal-footer{
        @apply flex gap-6 my-4;
    }
  }


.modal-container {
    @apply fixed inset-0 flex justify-center items-center min-h-screen bg-black bg-opacity-50;
}